import React, { InputHTMLAttributes } from 'react';
import { BaseFieldProps, Field } from 'redux-form';
import { Container } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id: string;
  type?: 'text' | 'date' | 'number' | 'email' | 'password';
  label?: string;
}

const Input: React.FC<Props & BaseFieldProps> = ({
  className,
  label,
  id,
  type = 'text',
  ...rest
}) => {
  const renderField: BaseFieldProps = ({
    input,
    meta: { touched, error, warning },
    ...rest
  }) => (
    <>
      <input {...input} {...rest} />
      {touched &&
        ((error && <div className="alert alert-danger">{error}</div>) ||
          (warning && <div className="alert alert-warning">{warning}</div>))}
    </>
  );

  return (
    <Container className={className}>
      {label && (
        <label htmlFor={id} className="register-label">
          {label}
        </label>
      )}
      <Field
        {...rest}
        component={renderField}
        type={type}
        id={id}
        className="form-control register-placeholder"
      />
    </Container>
  );
};

export default Input;
