export const START = '/';
export const SIGN_IN = '/signin';
export const SIGN_UP = '/signup';
export const CERTIFICATE_AUTENTICATION = '/autenticacao/:idDoCertificado';
export const CERTIFICATE_VOID = '/autenticacao/';
export const LOGIN_CODE = '/login/:clientCode';
export const TUTORIAL = '/tutorial';
export const CERTIFICATE = '/tutorial-certificate';
export const QUIZ = '/quiz';
export const MAIN_SCREEN = '/main-screen';
export const PF_LESSONS = '/lessons';
export const ADDITIONAL_LESSONS = '/additional-lessons';
export const VIEWED_LESSONS = '/viewed-lessons';
export const WATCH = '/watch';
export const THEME_DETAILS = '/theme-details';
