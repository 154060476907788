import { AxiosResponse } from 'axios';
import { all, takeEvery, put } from 'redux-saga/effects';
import { validateBr } from 'js-brasil';
import { CnpjResponse, findCnpj } from 'src/services/receitaService';
import { change, updateSyncErrors, clearFields } from 'redux-form';
import { CepResponse, findCep } from 'src/services/brasilApiService';
import {
  selectAdmnistrativeRegion,
  selectNationalRegion,
} from 'src/pages/SignUp/utils/regions';
import { ReduxAction, ReduxTypes } from '../ReduxForm/types';
import { startLoadingAction, stopLoadingAction } from './actions';

function* searchCnpjData(
  action: Extract<ReduxAction, { type: ReduxTypes.CHANGE }>,
) {
  const {
    payload,
    meta: { field, form },
  } = action;

  let cnpj = '';

  if (payload) {
    cnpj = payload.replace(/\D/g, '') as string;
  }

  if (form === 'signUp' && field === 'cnpj' && payload && cnpj.length === 14) {
    try {
      if (!validateBr.cnpj(cnpj)) {
        throw Error;
      }

      yield put(startLoadingAction());
      const response: AxiosResponse<CnpjResponse> = yield findCnpj(cnpj);
      const {
        atividade_principal: [{ text, code }],
        nome,
        cep,
        complemento,
        numero,
        status,
      } = response.data;

      yield put(change('signUp', 'companyName', nome));
      yield put(change('signUp', 'cep', cep.replace('.', '')));
      yield put(change('signUp', 'complement', complemento));
      yield put(change('signUp', 'number', numero));
      yield put(change('signUp', 'CNAE', `${code} - ${text}`));

      if (status === 'ERROR') {
        throw Error;
      }
      yield put(stopLoadingAction());
    } catch (err) {
      yield put(
        clearFields(
          'signUp',
          true,
          true,
          'companyName',
          'cep',
          'complement',
          'number',
          'CNAE',
        ),
      );
      if (!validateBr.cnpj(cnpj)) {
        yield put(
          updateSyncErrors(
            'signUp',
            { cnpj: 'CNPJ não encontrado!' },
            undefined,
          ),
        );
      }
      yield put(stopLoadingAction());
    }
  }
}

function* searchCep(action: Extract<ReduxAction, { type: ReduxTypes.CHANGE }>) {
  const {
    payload,
    meta: { field, form },
  } = action;

  let cep = '';

  if (payload) {
    cep = payload.replace(/\D/g, '') as string;
  }

  if (form === 'signUp' && field === 'cep' && payload && cep.length === 8) {
    try {
      if (!validateBr.cep(cep)) {
        throw Error;
      }

      yield put(startLoadingAction());
      const response: AxiosResponse<CepResponse> = yield findCep(cep);
      const { city, neighborhood, state, street } = response.data;

      const RA = selectAdmnistrativeRegion(neighborhood);
      const RN = selectNationalRegion(state);

      // Buscar Região Nacional
      // Buscar Região Administrativa

      yield put(change('signUp', 'city', city));
      yield put(change('signUp', 'publicPlace', street));
      yield put(change('signUp', 'neighborhood', neighborhood));
      yield put(change('signUp', 'state', state));
      yield put(change('signUp', 'administrativeRegion', RA));
      yield put(change('signUp', 'nationalRegion', RN));
      // Preencher Região Nacional
      // Preencher Região Administrativa

      yield put(stopLoadingAction());
    } catch (err) {
      yield put(clearFields('signUp', true, true, 'complement', 'number'));
      if (!validateBr.cep(cep)) {
        yield put(
          updateSyncErrors('signUp', { cep: 'CEP não encontrado!' }, undefined),
        );
      }
      yield put(stopLoadingAction());
    }
  }
}

export default all([
  takeEvery(ReduxTypes.CHANGE, searchCnpjData),
  takeEvery(ReduxTypes.CHANGE, searchCep),
]);
